import Container from '@components/Container';
import Typography from '@components/Typography';
import React from 'react';
import './Offerings.scss';
import Button from '@components/v2/Button';
import { Link } from '@reach/router';
import links from '@constants/links';

const Offerings: React.FC = () => {
  return (
    <div className="TDB-Home__offerings">
      <Container>
        <div className="TDB-Home__offerings__content">
          <Typography
            className="TDB-Home__offerings__content__title"
            fontSize="heading-1"
            color="neutral-white"
            fontWeight="bold"
            align="center"
          >
            Get started <br /> for as little as $50K
          </Typography>
          <Typography className="TDB-Home__offerings__content__description" color="neutral-50" align="center" fontSize="body-lg">
            Unit-based deployments make it easier than ever to try and buy. <br />
            Access to dedicated success engineers safeguard timely success at the lowest possible cost.
          </Typography>
          <div className="TDB-Home__offerings__content__ctas">
            <Link to={links.pricing}>
              <Button variant="outline">See our plans</Button>
            </Link>
            <Link to={links.contactSales}>
              <Button variant="outline">Talk to us</Button>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Offerings;
