import React from 'react';
import './index.scss';
import Footer from '@components/Footer';
import Header from '@components/Header';
import IndexLayout from '@layouts/index';
import Hero from '@page-components/home/Hero';
import CatalogColaborateAnalyze from '@page-components/home/CatalogColaborateAnalyze';
import Innovators from '@page-components/home/Innovators';
import Discover from '@page-components/home/Discover';
import Uniqueness from '@page-components/home/Uniqueness';
import Infrastructure from '@page-components/home/Infrastructure';
import Revolutionize from '@page-components/home/Revolutionize';
import Offerings from '@page-components/home/Offerings';

const Home = () => {
  return (
    <IndexLayout
      pageName="home"
      helmet={{
        title: 'TileDB • Designed for Discovery',
        description:
          'Transform your object store into a Trusted Research Environment. Use both common and frontier data to accelerate compliance-ready breakthroughs.',
      }}
    >
      <Header variant="transparent-white" />
      <main className="main TDB-Home">
        <Hero />
        <Revolutionize />
        <CatalogColaborateAnalyze />
        <Uniqueness />
        {/* <Infrastructure /> */}
        {/* <Innovators /> */}
        {/* <Discover /> */}
        <Offerings />
      </main>
      <Footer variant="light" />
    </IndexLayout>
  );
};

export default Home;
