import React, { useState } from 'react';
import Typography from '@components/Typography';
import Container from '@components/Container';
import classNames from 'classnames';
import SectionTitle from '../components/SectionTitle';
import analyzeGradient from './assets/analyze-gradient.jpg';
import analyzeImg from './assets/Analyze.png';
import organizeImg from './assets/Organise.png';
import structureImg from './assets/Structure.png';
import collaborateImg from './assets/Collaborate.png';
import './CatalogColaborateAnalyze.scss';

enum Section {
  Organize = 'Organize',
  Structure = 'Structure',
  Collaborate = 'Collaborate',
  Analyze = 'Analyze',
}

interface TabContent {
  functionalities: string[];
  image: string;
}

function getTabContent(tab: Section): TabContent {
  const contents: { [key in Section]: TabContent } = {
    [Section.Analyze]: {
      functionalities: [
        'Launch and run Jupyter notebooks on TileDB, pre-configured with the necessary libraries and resources for any scientific analysis.',
        'Publish custom dashboards so colleagues can interact with your results.',
        'Ingest and query terabytes of data and ‘000s of files on TileDB’s serverless distributed infrastructure, which dynamically scales to efficiently execute your tasks.',
      ],
      image: analyzeImg,
    },
    [Section.Organize]: {
      functionalities: [
        'Catalog all file formats from your local and cloud environments in bulk, attach meaningful descriptions, tags and metadata to enhance discoverability.',
        'Organize data for different projects and teams into teamspaces; manage access with fine-grained permissions.',
        'Facilitate safe search and discovery across the organization; maintain FAIR data standards.',
      ],
      image: organizeImg,
    },
    [Section.Structure]: {
      functionalities: [
        'Ingest novel data modalities into a more optimized array format, cost-effective for storage and downstream analyses.',
        'Achieve faster query performance by leveraging the array-powered engine to simply slice a subset of the rows you need instead of querying the entire dataset.',
        'Analyze your datasets directly on the UI with TileDB’s built-in widgets for specialized domain data, like PDFs and biomedical imaging. More built-in widgets are coming soon.',
      ],
      image: structureImg,
    },
    [Section.Collaborate]: {
      functionalities: [
        'Use TileDB’s asset views to facilitate access to massive datasets across multiple teams and projects without moving or duplicating the physical data on cloud storage.',
        'Control access to data with teamspaces, track and audit access history from dataset queries and writes, including statuses, logs and resource consumption.',
        'Trust that all processing and storage of your data, including patient data, is secure in our SOC-2 and HIPAA compliant system of record.',
      ],
      image: collaborateImg,
    },
  };
  return contents[tab];
}

const CatalogColaborateAnalyze: React.FC = () => {
  const [section, setSection] = useState(Section.Organize);

  return (
    <>
      {/*
       * preload images
       */}
      <link rel="preload" as="image" href={analyzeGradient} />
      <link rel="preload" as="image" href={analyzeImg} />
      <link rel="preload" as="image" href={organizeImg} />
      <link rel="preload" as="image" href={structureImg} />
      <link rel="preload" as="image" href={collaborateImg} />

      <div className="TDB-Home__cca">
        <SectionTitle
          title="Do all the work of discovery, all in one place."
          description="Stop struggling with data silos. TileDB gives teams a single, secure platform to manage, analyze, and maximize the value of all their R&D data."
        />
        <Container>
          <div>
            <div className="TDB-Home__cca__tabs">
              <Tab
                onClick={() => setSection(Section.Organize)}
                active={section === Section.Organize}
                title="Organize"
                description="Catalog any file type, both common and frontier data, all in one place."
                icon={
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.2504 5.63049C17.2504 4.95801 16.8465 4.00899 14.923 3.31298C13.674 2.86013 12.029 2.61108 10.2898 2.61108C8.5506 2.61108 6.90564 2.86014 5.65661 3.31298C3.73309 4.00913 3.32922 4.95801 3.32922 5.63049V16.2575C3.32922 16.93 3.73309 17.879 5.65454 18.575C6.90554 19.0278 8.5506 19.2769 10.2898 19.2769C12.0291 19.2769 13.674 19.0278 14.923 18.575C16.8466 17.8788 17.2504 16.93 17.2504 16.2575L17.2504 5.63049ZM10.2899 4.17964C13.8192 4.17964 15.6818 5.25806 15.6818 5.63059C15.6818 6.00302 13.8192 7.08144 10.2899 7.08144C6.76062 7.08144 4.89799 6.00302 4.89799 5.63049C4.89799 5.25806 6.76062 4.17964 10.2899 4.17964ZM4.89799 7.62263C5.14335 7.74654 5.39612 7.85527 5.65486 7.94814C6.90575 8.39511 8.55077 8.65003 10.2898 8.65003C12.0288 8.65003 13.674 8.3951 14.9248 7.94814C15.1842 7.85527 15.4376 7.74668 15.6835 7.62263V10.8459C15.6835 11.2185 13.8287 12.2969 10.2916 12.2969C6.75467 12.2969 4.89971 11.2185 4.89971 10.8459L4.89799 7.62263ZM10.2899 17.7083C6.76066 17.7083 4.89799 16.6299 4.89799 16.2574V12.8381C5.14335 12.962 5.39612 13.0707 5.65486 13.1634C6.90575 13.6164 8.55077 13.8655 10.2898 13.8655C12.0288 13.8655 13.674 13.6164 14.923 13.1636V13.1634C15.1825 13.0707 15.4358 12.962 15.6818 12.8381V16.2575C15.6818 16.6299 13.8192 17.7084 10.2899 17.7084L10.2899 17.7083ZM9.70175 11.3559H10.8782C11.1585 11.3559 11.4174 11.2064 11.5574 10.9636C11.6975 10.721 11.6975 10.422 11.5574 10.1794C11.4174 9.93678 11.1584 9.7873 10.8782 9.7873H9.70175C9.42152 9.7873 9.16263 9.93678 9.02258 10.1794C8.88253 10.422 8.88253 10.721 9.02258 10.9636C9.16263 11.2064 9.42155 11.3559 9.70175 11.3559ZM9.70165 14.8851H10.8781C11.1583 14.8851 11.4173 15.0346 11.5573 15.2773C11.6974 15.5199 11.6974 15.8189 11.5573 16.0615C11.4173 16.3042 11.1584 16.4537 10.8781 16.4537H9.70165C9.42145 16.4537 9.16253 16.3042 9.02248 16.0615C8.88243 15.8189 8.88243 15.52 9.02248 15.2773C9.16253 15.0346 9.42141 14.8851 9.70165 14.8851Z"
                    />
                  </svg>
                }
              />
              <Tab
                onClick={() => setSection(Section.Structure)}
                active={section === Section.Structure}
                title="Structure"
                description="Leverage the power of TileDB’s multi-dimensional array engine to structure complex, frontier data."
                icon={
                  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M2.41211 19.2958V11.7962H9.91172V19.2958H2.41211Z" fill="#4B5563" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M2.41211 10.1296V2.63H9.91172V10.1296H2.41211Z" fill="#4B5563" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M11.5783 19.2958V11.7962H19.0779V19.2958H11.5783Z" fill="#4B5563" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M11.5783 10.1296V3.46329H18.2446V10.1296H11.5783Z" fill="#4B5563" />
                  </svg>
                }
              />
              <Tab
                onClick={() => setSection(Section.Collaborate)}
                active={section === Section.Collaborate}
                title="Collaborate"
                description="Eliminate information silos without compromising data governance."
                icon={
                  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.2211 12.6295H15.7331C15.7926 12.9271 15.8521 13.2247 15.8521 13.5223V16.3198C15.8521 16.5579 15.7926 16.7364 15.7331 16.915H18.2329C18.5305 16.915 18.8281 16.6174 18.8281 16.3198V14.2365C18.8281 13.4033 18.1139 12.6295 17.2211 12.6295Z"
                      fill="#4B5563"
                    />
                    <path
                      d="M17.5782 9.71298C17.5782 8.52256 16.6259 7.57023 15.495 7.57023C14.3641 7.57023 13.4118 8.52256 13.4118 9.71298C13.4118 10.9034 14.3641 11.8557 15.495 11.8557C16.6259 11.8557 17.5782 10.8439 17.5782 9.71298Z"
                      fill="#4B5563"
                    />
                    <path
                      d="M5.25743 12.6295H3.76941C2.8766 12.6295 2.16235 13.3437 2.16235 14.2365V16.3198C2.16235 16.6174 2.45996 16.915 2.75756 16.915H5.19791C5.13839 16.7959 5.13839 16.5579 5.13839 16.3793V13.5818C5.07887 13.2247 5.13839 12.9271 5.25743 12.6295Z"
                      fill="#4B5563"
                    />
                    <path
                      d="M7.51922 9.71298C7.51922 8.52256 6.56688 7.57023 5.43599 7.57023C4.3051 7.57023 3.35277 8.52256 3.35277 9.71298C3.35277 10.9034 4.3051 11.8557 5.43599 11.8557C6.56688 11.8557 7.51922 10.8439 7.51922 9.71298Z"
                      fill="#4B5563"
                    />
                    <path
                      d="M12.757 11.6176H8.29298C7.22161 11.6176 6.3288 12.5104 6.3288 13.5818V16.3793C6.3288 16.7364 6.56688 16.9745 6.92401 16.9745H14.0665C14.4236 16.9745 14.6617 16.7364 14.6617 16.3793V13.5818C14.6617 12.5104 13.8284 11.6176 12.757 11.6176Z"
                      fill="#4B5563"
                    />
                    <path
                      d="M10.4357 10.4867C11.8642 10.4867 13.0546 9.29633 13.0546 7.80831C13.0546 6.3203 11.8642 5.12988 10.4357 5.12988C9.00723 5.12988 7.81682 6.3203 7.81682 7.80831C7.81682 9.29633 8.94771 10.4867 10.4357 10.4867Z"
                      fill="#4B5563"
                    />
                  </svg>
                }
              />
              <Tab
                onClick={() => setSection(Section.Analyze)}
                active={section === Section.Analyze}
                title="Analyze"
                description="Execute data analysis at scale right where the data lives, using familiar tools and libraries."
                icon={
                  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.33784 6.85493L6.85159 11.5491C6.76002 11.8384 6.4914 12.0351 6.18782 12.0351H2.60859C2.224 12.0351 1.91235 11.7234 1.91235 11.3389C1.91235 10.9544 2.22397 10.6428 2.60859 10.6428H5.67819L7.66543 4.36592C7.86967 3.72047 8.78179 3.71699 8.99114 4.36079L12.5455 15.2935L14.2877 11.0735C14.3954 10.8129 14.6495 10.643 14.9312 10.643H17.8821C18.2665 10.643 18.5781 10.9546 18.5781 11.3391C18.5781 11.7235 18.2665 12.0353 17.8821 12.0353H15.397L13.1113 17.572C12.8643 18.1702 12.0059 18.1371 11.8057 17.5216L8.33784 6.85493Z"
                      fill="#4B5563"
                    />
                  </svg>
                }
              />
            </div>
            <div className={`TDB-Home__cca__container TDB-Home__cca__container--${section}`}>
              <div className="TDB-Home__cca__content">
                <ContentTemplate {...getTabContent(section)} />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

interface TabProps {
  title: string;
  description: string;
  icon: JSX.Element;
  active: boolean;
  onClick: () => void;
}

const Tab: React.FC<TabProps> = (props) => {
  const { title, description, icon, active, onClick } = props;

  return (
    <button className={classNames('TDB-Home__cca__tab', { 'TDB-Home__cca__tab--active': active })} onClick={onClick}>
      <div className="TDB-Home__cca__tab__icon">{icon}</div>
      <Typography className="TDB-Home__cca__tab__title" color="neutral-600" fontWeight="semi-bold" fontSize="subheading-2">
        {title}
      </Typography>
      <Typography className="TDB-Home__cca__tab__description" color="neutral-600" fontWeight="medium" fontSize="body">
        {description}
      </Typography>
    </button>
  );
};

interface ContentTemplateProps {
  image: string;
  functionalities: string[];
}

const ContentTemplate: React.FC<ContentTemplateProps> = (props) => {
  const { image, functionalities = [] } = props;

  return (
    <div className="TDB-Home__cca__content-template">
      <div className="TDB-Home__cca__content-template__text">
        {functionalities.map((f) => (
          <div key={f} className="TDB-Home__cca__content-template__functionality">
            <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M24.0365 44C26.7664 44 29.3352 43.4771 31.7428 42.4314C34.1505 41.3856 36.2754 39.9447 38.1176 38.1085C39.9538 36.2724 41.3947 34.1444 42.4405 31.7246C43.4802 29.3048 44 26.7299 44 24C44 21.264 43.4771 18.6922 42.4314 16.2845C41.3856 13.8708 39.9416 11.7428 38.0994 9.90059C36.2572 8.05837 34.1262 6.61438 31.7063 5.56863C29.2865 4.52288 26.7117 4 23.9818 4C21.2458 4 18.674 4.51983 16.2663 5.55951C13.8526 6.59918 11.7276 8.04013 9.89147 9.88236C8.05533 11.7185 6.61438 13.8434 5.56863 16.2572C4.52288 18.6648 4 21.2336 4 23.9635C4 26.6934 4.51984 29.2652 5.55951 31.679C6.59918 34.0927 8.04013 36.2177 9.88235 38.0538C11.7185 39.8839 13.8434 41.3218 16.2572 42.3675C18.6648 43.4072 21.2336 43.927 23.9635 43.927L24.0365 44ZM21.3461 34.0593C21.0056 34.0593 20.6986 33.9833 20.425 33.8313C20.1453 33.6793 19.8687 33.433 19.5951 33.0926L13.9316 26.1158C13.7857 25.9273 13.6702 25.7237 13.585 25.5048C13.4999 25.2798 13.4574 25.0549 13.4574 24.8299C13.4574 24.3678 13.6124 23.9818 13.9225 23.6717C14.2326 23.3555 14.6095 23.1974 15.0534 23.1974C15.3269 23.1974 15.5853 23.2582 15.8285 23.3798C16.0717 23.5014 16.3058 23.7112 16.5308 24.0091L21.2093 30.0556L30.3201 15.4546C30.6971 14.8527 31.1652 14.5518 31.7246 14.5518C32.1502 14.5518 32.5363 14.6855 32.8828 14.953C33.2233 15.2206 33.3935 15.5884 33.3935 16.0565C33.3935 16.2754 33.3388 16.4973 33.2294 16.7223C33.1199 16.9412 33.0074 17.1479 32.8919 17.3425L22.9147 33.0287C22.7019 33.351 22.4557 33.5911 22.176 33.7492C21.8903 33.9012 21.5863 33.9772 21.264 33.9772L21.3461 34.0593Z"
                fill="#0077FF"
              />
            </svg>
            <Typography color="neutral-600" fontSize="body-lg" fontWeight="medium">
              {f}
            </Typography>
          </div>
        ))}
      </div>
      <div className="TDB-Home__cca__content-template__image" style={{ backgroundImage: `url(${image})` }} />
    </div>
  );
};

export default CatalogColaborateAnalyze;
