import './Revolutionize.scss';
import React from 'react';
import Typography from '@components/Typography';
import Container from '@components/Container';
import SectionTitle from '../components/SectionTitle';
import tiledbLogo from './assets/tiledb_logo.svg';
import tiledbLogoFull from './assets/tiledb_logo_full.svg';

const Revolutionize: React.FC = () => {
  return (
    <div className="TDB-Revolutionize">
      <SectionTitle
        title={
          <>
            Discovery deserves
            <br />a fit-for-purpose database
          </>
        }
        description="Unite all the data used in scientific discovery, structured and unstructured, common and frontier, in a single, secure destination."
      />
      <Container>
        <div className="TDB-SolutionIdeasWrapper">
          <img className="TDB-SolutionIdeas__logo-full" src={tiledbLogoFull} alt="TileDB logo" />
          <div className="TDB-SolutionIdeas">
            {/* Item */}
            <div className="TDB-SolutionIdeas__item">
              <div className="TDB-SolutionIdeas__quote">
                <div className="TDB-SolutionIdeas__header">
                  <Typography as="h3" fontSize="heading-3" fontWeight="bold" color="neutral-900">
                    Organize
                  </Typography>
                </div>
                <Typography className="TDB-SolutionIdeas__description" fontSize="heading-5" color="neutral-600" fontWeight="medium">
                  Ingest, organize, browse and manage assets using workspaces and teamspaces.
                </Typography>
              </div>

              {/* <div className="TDB-SolutionIdeas__footer">
                <Typography color="neutral-600" fontWeight="semi-bold">
                  Instead of
                </Typography>
                <div className="TDB-SolutionIdeas__icons">
                  <img src={bigQuery} alt="big-query" />
                  <img src={snowFlake} alt="snowFlake" />
                  <img src={databricks} alt="snowFlake" />
                </div>
              </div> */}
            </div>
            {/* End of Item */}
            <div className="TDB-SolutionIdeas__item TDB-SolutionIdeas__item--right TDB-SolutionIdeas__item--green">
              <div className="TDB-SolutionIdeas__quote">
                <div className="TDB-SolutionIdeas__header">
                  <Typography as="h3" fontSize="heading-3" fontWeight="bold" color="neutral-900">
                    Structure
                  </Typography>
                </div>
                <Typography className="TDB-SolutionIdeas__description" fontSize="heading-5" color="neutral-600" fontWeight="medium">
                  Use arrays to optimize formats for frontier data, making them ready for efficient downstream analysis.
                </Typography>
              </div>

              {/* <div className="TDB-SolutionIdeas__footer">
                <Typography className="TDB-SolutionIdeas__footer-note" color="neutral-600" fontWeight="semi-bold">
                  Instead of
                </Typography>
                <div className="TDB-SolutionIdeas__icons">
                  <img src={velsera} alt="velsera" />
                  <img src={codeOcean} alt="code-ocean" />
                  <img src={dnaNexus} alt="dna-nexus" />
                </div>
              </div> */}
            </div>
            {/* End of Item */}

            <div className="TDB-SolutionIdeas__item TDB-SolutionIdeas__item--bottom TDB-SolutionIdeas__item--magenta">
              <div className="TDB-SolutionIdeas__quote">
                <div className="TDB-SolutionIdeas__header">
                  <Typography as="h3" fontSize="heading-3" fontWeight="bold" color="neutral-900">
                    Collaborate
                  </Typography>
                </div>
                <Typography className="TDB-SolutionIdeas__description" fontSize="heading-5" color="neutral-600" fontWeight="medium">
                  Enable secure cross-team collaboration and data sharing while maintaining compliance with major regulatory frameworks.
                </Typography>
              </div>

              {/* <div className="TDB-SolutionIdeas__footer">
                <Typography color="neutral-600" fontWeight="semi-bold">
                  Instead of
                </Typography>
                <div className="TDB-SolutionIdeas__icons">
                  <img src={box} alt="box" />
                  <img src={googleDrive} alt="google-drive" />
                  <img src={dropbox} alt="dropbox" />
                </div>
              </div> */}
            </div>
            {/* End of Item */}
            <div className="TDB-SolutionIdeas__item TDB-SolutionIdeas__item--right TDB-SolutionIdeas__item--bottom TDB-SolutionIdeas__item--blue">
              <div className="TDB-SolutionIdeas__quote">
                <div className="TDB-SolutionIdeas__header">
                  <Typography as="h3" fontSize="heading-3" fontWeight="bold" color="neutral-900">
                    Analyze
                  </Typography>
                </div>
                <Typography className="TDB-SolutionIdeas__description" fontSize="heading-5" color="neutral-600" fontWeight="medium">
                  Facilitate data analysis & modeling through pre-built workflows, integrated Jupyter notebooks, and customizable
                  dashboards.
                </Typography>
              </div>
              {/* 
              <div className="TDB-SolutionIdeas__footer">
                <Typography className="TDB-SolutionIdeas__footer-note" color="neutral-600" fontWeight="semi-bold">
                  Instead of
                </Typography>
                <div className="TDB-SolutionIdeas__icons">
                  <img src={alation} alt="alation" />
                  <img src={atlan} alt="atlan" />
                  <img src={collibra} alt="collibra" />
                </div>
              </div> */}
            </div>
            {/* End of Item */}
            <div className="TDB-SolutionIdeas__eclipse">
              <img className="TDB-SolutionIdeas__logo" src={tiledbLogo} alt="tiledb logo" />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Revolutionize;
